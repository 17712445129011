import axios, { AxiosInstance } from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import "./App.css";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import useSound from "use-sound";
import ConfettiExplosion from "react-confetti-explosion";

function App() {
	var client: AxiosInstance = axios.create({
		baseURL: "https://gate.dopple.site/open/",
		headers: {
			"Access-Control-Allow-Cross-Origin": "*",
			"Content-Type": "application/json",
		},
	});

	const navigate = useNavigate();
	const expires: Date = new Date(Date.now());
	const month = 1000 * 60 * 60 * 24 * 30; // 30 days
	expires.setTime(expires.getTime() + month);

	const cookies = new Cookies(null, { path: "/", expires: expires });

	const queryParameters = new URLSearchParams(window.location.search);
	const door = queryParameters.get("door");
	const automatic = queryParameters.get("automatic");
	var pin = cookies.get("doorpin");

	const [play_gate_sound] = useSound("/sounds/opening_gate.wav");
	const [play_achterdeur_sound] = useSound("/sounds/success.mp3");
	const [play_glazendeur_sound] = useSound("/sounds/glass.mp3");

	// Temporary fallback for switching from localStorage to a cookie (JumJum)
	if (!pin) {
		pin = localStorage.getItem("doorpin");
	}

	const [explosion, setExplosion] = useState<"voordeur" | "achterdeur" | "glazendeur" | "poort" | "none">("none");

	function handleSubmit(event: any) {
		event.preventDefault();

		const doorName = event.target.id;
		const pinCode = event.target.form.pin.value;
		cookies.set("doorpin", pinCode);
		toast.loading("Sending pin...");

		client.get(`${doorName}/${pinCode}`).then((response) => {
			if (response.data.error === "PIN_INCORRECT") toast.error(`Unlock unsuccessful`);
			else if (response.data.error === "NOT_ALLOWED") toast.error("Unlock not allowed");
			else if (response.data.error) toast.error(`${response.data.error}`);
			else {
				if (doorName === "poort") {
					play_gate_sound();
					setExplosion(doorName)
				}
				
				if (doorName === "achterdeur") {
					play_achterdeur_sound();
					setExplosion(doorName)
				}
				
				if (doorName === "glazendeur") {
					play_glazendeur_sound();
					setExplosion(doorName)
				}
				if (doorName === "voordeur") {
					setExplosion(doorName)
				}
				toast.success(`${response.data.info}`);
				navigate(`?door=${doorName}`);
			}
		});
	}

	useEffect(() => {
		if (automatic && door && pin) {
			client.get(`${door}/${pin}`).then((response) => {
				if (response.data.error === "PIN_INCORRECT") toast.error(`Unlock unsuccessful`);
				else if (response.data.error === "NOT_ALLOWED") toast.error("Unlock not allowed");
				else if (response.data.error) toast.error(`${response.data.error}`);
				else {
					if (door === "poort") {
						play_gate_sound();
					}
					
					if (door === "achterdeur") {
						play_achterdeur_sound();
					}
					
					if (door === "glazendeur") {
						play_glazendeur_sound();
					}
					toast.success(`${response.data.info}`);
					navigate(`?door=${door}`);
				}
			});
		}
	}, [automatic, client, door, navigate, pin, play_achterdeur_sound, play_gate_sound, play_glazendeur_sound]);

	return (
		<div className="App">
			<header className="App-header">
				<Image
					width={300}
					src="/DoppleLogoGray.png"
				></Image>
				<br />
				<br />
				<br />
				<br />
				<Form>
					<p>Enter your pin</p>
					<Form.Control
						autoComplete="current-password"
						type="password"
						id="pin"
						defaultValue={pin ? pin : ""}
					></Form.Control>
					<Col>
						<br />
						<br />
						<p>And open a door </p>
						<Row>
							<Row>
								<Col></Col>
								<Col>
									{explosion === "poort" && (
										<ConfettiExplosion
											particleCount={250}
											particleSize={12}
											duration={2500}
											height={"120vh"}
											width={1000}
											colors={["#DC3545"]}
											onComplete={() => setExplosion("none")}
											force={0.7}
										/>
									)}
								</Col>
								<Col></Col>
							</Row>
							<Button
								variant="danger"
								size="lg"
								id="poort"
								onClick={handleSubmit}
							>
								Poort
							</Button>
						</Row>{" "}
						<br />
						<Row>
							<Col></Col>
							<Col>
								{explosion === "achterdeur" && (
									<ConfettiExplosion
										particleCount={250}
										particleSize={12}
										duration={2500}
										height={"120vh"}
										width={1000}
										colors={["#FFC107"]}
										onComplete={() => setExplosion("none")}
										force={0.7}
									/>
								)}
							</Col>
							<Col></Col>
						</Row>
						<Row>
							<Button
								variant="warning"
								size="lg"
								id="achterdeur"
								onClick={handleSubmit}
							>
								Achterdeur
							</Button>
						</Row>{" "}
						<br />
						<Row>
							<Row>
								<Col></Col>
								<Col>
									{explosion === "glazendeur" && (
										<ConfettiExplosion
											particleCount={250}
											particleSize={12}
											duration={1500}
											height={"120vh"}
											width={1000}
											colors={["#007BFF", "#00FBFF", "#FFFFFF"]}
											onComplete={() => setExplosion("none")}
											force={0.4}
										/>
									)}
								</Col>
								<Col></Col>
							</Row>
							<Button
								variant="primary"
								size="lg"
								id="glazendeur"
								onClick={handleSubmit}
							>
								Glazendeur
							</Button>
						</Row>{" "}
						<br />
						<Row>
							<Row>
								<Col></Col>
								<Col>
									{explosion === "voordeur" && (
										<ConfettiExplosion
											particleCount={250}
											particleSize={12}
											duration={2500}
											height={"120vh"}
											width={1000}
											colors={["#6C757D"]}
											onComplete={() => setExplosion("none")}
											force={0.7}
										/>
									)}
								</Col>
								<Col></Col>
							</Row>
							<Button
								variant="secondary"
								size="lg"
								id="voordeur"
								onClick={handleSubmit}
							>
								Voordeur
							</Button>
						</Row>
					</Col>
				</Form>
			</header>
		</div>
	);
}

export default App;
